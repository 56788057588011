import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const GlobalStyle = createGlobalStyle`
  div.ant-select-dropdown {
    border-radius: 20px;
  }
  @font-face {
    font-family: europa;
    src: url(https://use.typekit.net/af/f3ba4f/00000000000000003b9b12fa/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff2"),
        url(https://use.typekit.net/af/f3ba4f/00000000000000003b9b12fa/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff"),
        url(https://use.typekit.net/af/f3ba4f/00000000000000003b9b12fa/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    }
    @font-face {
        font-family: europa;
        src: url(https://use.typekit.net/af/3e64fb/00000000000000003b9b12fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff2"),
            url(https://use.typekit.net/af/3e64fb/00000000000000003b9b12fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff"),
            url(https://use.typekit.net/af/3e64fb/00000000000000003b9b12fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("opentype");
        font-display: swap;
        font-style: normal;
        font-weight: 300;
    }
    @font-face {
        font-family: europa;
        src: url(https://use.typekit.net/af/4eabcf/00000000000000003b9b12fd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff2"),
            url(https://use.typekit.net/af/4eabcf/00000000000000003b9b12fd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff"),
            url(https://use.typekit.net/af/4eabcf/00000000000000003b9b12fd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("opentype");
        font-display: swap;
        font-style: normal;
        font-weight: 400;
    }
    @font-face {
        font-family: europa;
        src: url(https://use.typekit.net/af/a386b3/00000000000000003b9b12f9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("woff2"),
            url(https://use.typekit.net/af/a386b3/00000000000000003b9b12f9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("woff"),
            url(https://use.typekit.net/af/a386b3/00000000000000003b9b12f9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("opentype");
        font-display: swap;
        font-style: italic;
        font-weight: 300;
    }
    @font-face {
        font-family: europa;
        src: url(https://use.typekit.net/af/e32ad9/00000000000000003b9b12fb/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("woff2"),
            url(https://use.typekit.net/af/e32ad9/00000000000000003b9b12fb/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("woff"),
            url(https://use.typekit.net/af/e32ad9/00000000000000003b9b12fb/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("opentype");
        font-display: swap;
        font-style: italic;
        font-weight: 400;
    }
    @font-face {
        font-family: europa;
        src: url(https://use.typekit.net/af/a6fa4a/00000000000000003b9b12fc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("woff2"),
            url(https://use.typekit.net/af/a6fa4a/00000000000000003b9b12fc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("woff"),
            url(https://use.typekit.net/af/a6fa4a/00000000000000003b9b12fc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("opentype");
        font-display: swap;
        font-style: italic;
        font-weight: 700;
    }
    body, h1, h2, h3, h4, h5, h6, p {
        font-family: europa,sans-serif;
  }
  .mymodal {
     position: fixed;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     border: 1px solid #ccc;
     background: #fff;
     overflow: auto;
     border-radius: 12px;
     outline: none;
     padding: 20px;
     width: 100%;
     max-width: 700px;
    }
    .myoverlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.65);
      z-index: 99999;
  }
  .sticky-inner-wrapper {
    z-index: 1000 !important;
  }
  .border-bottom {
    border-bottom: 1px solid #f0f0f0;
  }
  input[type=email]:focus {
    outline: 0;
  }
  .modal-pop h5 {font-size: 22px;color: #0D1115;}
  .modal-pop h6 {font-size: 21px;color: #6E6E6E;margin-bottom: 0;}
  .modal-pop .form-label {font-size: 20px;display: block;width: 100%;color: #6E6E6E;margin-bottom: 5px;}
  .modal-set {margin-bottom: 20px;}
  .modal-set2 {margin-bottom: 10px;}
  .modal-set2 .ant-select-single.ant-select-show-arrow .ant-select-selection-item {line-height: 2.5;}
  .modal-set2 .ant-select-selector, .modal-set2 .ant-input {
    border: 1px solid #D8E0E8 !important;
    width: 100%;
  }
  .modal-set2 .ant-select-selector {
    height: 40px !important;
    border-radius: 30px !important;
  }
  .modal-set2 .ant-input {border-radius: 20px !important;}
  .modal-set2 .ant-select {width: 100%;}
  .modal-set2 .ant-checkbox-wrapper {color: #6E6E6E;font-size: 15px;}
  .modal-set2 .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #314755;
    border-color: #314755;
  }
  .modal-set2 .ant-checkbox:hover .ant-checkbox-inner, .modal-set2 .ant-checkbox-checked::after {border-color: #314755 !important;}
  .modal-set2 button {text-transform: uppercase;font-weight: bold;font-size: 14px;border-radius: 30px;}
  .modal-close {
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 15px;
    cursor: pointer;
  }
  .review-modal .review-contain-right button {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    border-radius: 30px;
    height: 38px;
    background-color: #0585FF;
    border-color: #0585FF;
    color: #fff;
    padding: 4px 25px;
    border: 0;
    cursor: pointer;
  }
  .review-modal .review-contain-right button:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
  .review-modal .review-contain-right button:focus {
    outline: 0;
  }
  * {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ::selection {
    background: ${themeGet("primary.0", "#0077cc")};
    color: ${themeGet("color.1", "#ffffff")};
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    font-family: europa,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: europa,sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    background-color: ${themeGet("color.1", "#ffffff")};

    main.ant-layout-content {
      flex: 1 0 auto;
    }
  }

  .hotel-submission-form {
    .ant-progress-outer {
      position: fixed;
      z-index: 9;
      left: 0;
      top: auto;
    }
    .gm-style {
      > input[type="text"] {
        left: 9px !important;
        top: 46px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .hotel-form-location {
      .ant-card-head-title {
        font-size: 15px;
        line-height: 18px;
        font-weight: 700;
        color: ${themeGet("text.0", "#2C2C2C")};
      }
      .ant-card-body p {
        display: flex;
        color: ${themeGet("text.2", "#777777")};
        justify-content: flex-start;
        strong {
          color: ${themeGet("text.0", "#2C2C2C")};
          width: 30%;
        }
      }
    }
  }

  .ant-popover {
    &.profile_menu {
      z-index: 9999;
    }
    .ant-popover-content {
      .ant-popover-inner {
        .ant-popover-inner-content {
          ul.ant-menu  {
            border: 0;
            &.account_menu {
              li {
                color: ${themeGet("text.0", "#2C2C2C")};
                font-size: 15px;
                line-height: 18px;
                font-weight: 400;
                height: auto;
                transition: color 0.2s ease-in-out;
                &.ant-menu-item-selected,
                &.ant-menu-item-active {
                  background-color: transparent;
                }
                a {
                  padding: 8px 0;
                  color: ${themeGet("text.0", "#2C2C2C")};
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet("primary.0", "#0077cc")};
                  }
                  &.active {
                    font-weight: 700;
                    color: ${themeGet("primary.0", "#0077cc")};
                  }
                }
                button {
                  padding: 0;
                  border: 0;
                  cursor: pointer;
                  padding: 8px 0;
                  background-color: transparent;
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet("primary.0", "#0077cc")};
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .DateRangePicker {
    .DateRangePickerInput {
      .DateRangePicker_picker {
        .DayPicker_weekHeader {
          .DayPicker_weekHeader_ul {
            .DayPicker_weekHeader_li {
              color: ${themeGet("text.0", "#2C2C2C")};
              small {
                font-size: 13px;
              }
            }
          }
        }
        .DayPicker_focusRegion {
          .CalendarMonthGrid {
            .CalendarMonth {
              .CalendarMonth_caption {
                font-size: 16px;
                color: ${themeGet("text.0", "#2C2C2C")};
              }
            }
          }
        }
        .DayPickerNavigation {
          .DayPickerNavigation_button {
            &:focus {
              outline: none;
            }
          }
        }
        .DayPickerKeyboardShortcuts_buttonReset {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .ant-tooltip {
    font-size: 13px;
    font-weight: 700;
    .ant-tooltip-inner {
      box-shadow: none;
      text-align: center;
      border-radius: 3px;
      min-height: 26px;
      padding: 4px 10px;
    }
  }

  .ant-select-dropdown {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 12px 12px;
        line-height: 1;
        &.ant-select-dropdown-menu-item-active {
          background-color: rgba(0, 132, 137, 0.1);
        }
      }
    }
  }

  .view_with__popup {
    &.room_guest__component {
      &.active {
        min-height: 54px;
      }
      &.alt {
        .popup_container {
          #popup {
            > div {
              > div {
                padding: 0;
              }
            }
          }
        }
        &.active {
          min-height: inherit;
        }
      }
    }
  }

  .ant-dropdown {
    &.social_share_menu {
      z-index: 9999 !important;
      
      .ant-menu {
        padding: 7px 0;
        min-width: 150px;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    
        .ant-menu-item {
          margin: 0;
          padding: 0;
          height: inherit;
          line-height: inherit;
          > div {
            padding: 15px 20px;
            color: ${themeGet("text.0", "#2C2C2C")};
            svg {
              margin-right: 8px;
            }
          }

          &:hover {
            background-color: ${themeGet("color.2", "#F7F7F7")};
          }
        }
      }
    }
  }

  .ant-drawer,
  .ant-modal-mask,
  .ant-modal-wrap  {
    z-index: 10000 !important;
  }
  .ant-drawer {
    &.filter_drawer {
      pointer-events: none;
      .ant-drawer-mask {
        display: none;
      }
      &.ant-drawer-bottom {
        &.ant-drawer-open {
          .ant-drawer-content-wrapper {
            box-shadow: none;
          }
        }
      }
      .ant-drawer-content-wrapper {
        height: calc(100vh - 152px) !important;
        pointer-events: all;
        .ant-drawer-wrapper-body {
          height: 100%;
          .ant-drawer-body {
            height: 100%;
            padding-top: 0;
            overflow: hidden;
          }
        }
      }
    }
  }

  .ant-modal-wrap {
    .ant-modal-content {
      box-shadow: 0 1px 10px rgba(0,0,0,0.16);
    }
    &.review_modal {
      .ant-modal {
        max-width: 1170px;
        box-sizing: border-box;
        @media only screen and (max-width: 1260px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media only screen and (max-width: 767px) {
          top: 0;
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        .ant-modal-body {
          padding-left: 60px;
          padding-right: 60px;
          padding-bottom: 60px;
          padding-top: 30px;
          @media only screen and (max-width: 1260px) {
            padding: 40px 30px;
          }
        }
      }
      .image_uploader{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        @media only screen and (max-width: 667px) {
          flex-wrap: wrap;
        }
        .ant-upload{
          @media only screen and (max-width: 667px) {
            margin-bottom: 10px;
          }
          &.ant-upload-drag{
            border: 0;
            border-radius: 0;
            background: transparent;
            .ant-upload{
              padding: 0;
              display: block;
              width: auto;
              height:auto;
            }
          }
          &.ant-upload-drag-hover{
            .image-drag-area{
              border-color: #48bdc1;
            }
          }
        }
        .ant-upload-list{
          float: none;
          display: flex;
          .ant-upload-list-item{
            width: 125px;
            height: 125px;
            border-radius: 3px;
          }
        }
        .ant-upload-drag-container{
          display: block;
        }
      }
    }
  }

  .gm-style-iw-c {
    box-shadow: none !important;
  }
  .gm-style-iw {
    padding: 0 !important;
    border-radius: 3px !important;
    width: 270px !important;
    z-index: 1;
    .gm-style-iw-d {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      max-width: 100% !important;
    }
    .info_window_card {
      width: 270px;
      margin-bottom: 0;
      > div {
        > img {
          width: 100%;
          height: 190px;
          object-fit: cover;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .content_wrapper {
        > div + div {
          margin-top: 2px;
          margin-bottom: 4px;
        }
        .meta_wrapper {
          .rating {
            margin-top: 5px;
          }
        }
      }
    }
    button[title="Close"].gm-ui-hover-effect {
      width: 26px !important;
      height: 26px !important;
      padding: 6px !important;
      border-radius: 50%;
      margin: 0 !important;
      top: 14px !important;
      right: 11px !important;
      opacity: 1;
      align-items: center;
      display: inline-flex !important;
      background-color: ${themeGet("color.1", "#ffffff")} !important;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      > img {
        margin: 0 !important;
      }
    }
  }

  .ant-modal-wrap {
    &.image_gallery_modal {
      .ant-modal {
        top: 50px;
        .ant-modal-content{
          box-shadow: none;
          padding: 0 30px;
          background: transparent;
          @media only screen and (max-width: 480px) {
            padding: 0 20px;
          }
          .ant-modal-body {
            max-width: 1170px;
            padding: 0;
            margin: 0 auto; 
            @media only screen and (max-width: 1440px) {
              position: relative;
            }
          }
        }
        .image_gallery_close {
          background: transparent;
          border: 0;
          width: 60px;
          height: 60px;
          position: absolute;
          top: -20px;
          right: 40px;
          &:focus,
          &:hover {
            outline: none;
          }
          @media only screen and (max-width: 1440px) {
            top: 0;
            right: 0;
            svg {
              path {
                opacity: 0.8;
                fill: ${themeGet("color.1", "#ffffff")};
                transition: all 0.3s ease;
              }
            }
            &:hover {
              svg {
                path {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .quantity {
    button.btn {
      border: 1px solid ${themeGet("primary.0", "#0077cc")};
      svg {
        fill: ${themeGet("primary.0", "#0077cc")};
      }
      &:hover {
        background-color: ${themeGet("primary.0", "#0077cc")};
        svg {
          fill: #ffffff;
        }
      }
    }
    input[type="number"],
    input[type="number"].qnt-input {
      color: ${themeGet("text.0", "#2C2C2C")};
    }
  }

  .mobile-header {
    &.ant-drawer {
      z-index: 10000;
    }
    .ant-drawer-body {
      padding: 0;
      .auth-menu {
        border: 0;
        display: flex;
        margin: 0;
        padding: 25px 20px;
        align-items: center;
        background-color: ${themeGet("color.2", "#F7F7F7")};
        @media only screen and (max-width: 991px) {
            display: block;
          }
        li {
          height: auto;
          line-height: 1;
          padding: 0;
          margin: 0;
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 12px 15px;
            border-radius: 3px;
            font-size: 15px;
            // font-weight: 700;
            color: ${themeGet("text.0", "#2C2C2C")};
          }
          &:last-child {
            a {
              color: ${themeGet("color.1", "#ffffff")};
              background-color: ${themeGet("primary.0", "#0077cc")};
              transition: opacity 0.2s ease;
              &:hover {
                opacity: 0.9;
              }
            }
          }
        }
      }
      .main-menu {
        border: 0;
        padding-top: 10px;
        li {
          padding: 0;
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            font-size: 15px;
            padding: 0 31px;
            border-left: 4px solid transparent;
            color: ${themeGet("text.0", "#2C2C2C")};
            &.active {
              font-weight: 700;
              border-color: ${themeGet("primary.0", "#0077cc")};
              color: ${themeGet("primary.0", "#0077cc")};
            }
          }
          button {
            display: block;
            text-align: left;
            width: 100%;
            border: 0;
            padding: 0 31px;
            cursor: pointer;
            background-color: transparent;
            color: ${themeGet("text.0", "#2C2C2C")};
            transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            @media (max-width: 991px){
                border-left: 4px solid transparent;;
            }
            &:hover {
              color: ${themeGet("primary.0", "#0077cc")};
            }
            &focus {
              outline: 0;
            }
          }
          &:hover {
            a {
              color: ${themeGet("primary.0", "#0077cc")};
            }
          }
        }
      }
    }
  }

  .pac-container {
    border: 0;
    z-index: 99999;
    padding: 5px 0;
    margin-top: 0;
    border-radius: 3px;
    box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
    @media only screen and (max-width: 991px) {
      margin-top: 0;
    }
    &::after {
      display: none;
    }
    .pac-item {
      border-top: 0;
      line-height: 30px;
      padding: 10px 14px;
      cursor: pointer;
      font-size: 13px;
      color: ${themeGet("text.1", "#909090")};
      .pac-icon {
        margin-top: 6px;
      }
      .pac-item-query {
        font-size: 15px;
        font-weight: 600;
        color: ${themeGet("text.0", "#2C2C2C")};  
        .pac-matched {
          font-weight: 700;
          color: ${themeGet("color.0", "#000000")};
        }
      } 
      &:hover {
        background-color: ${themeGet("color.2", "#F7F7F7")};
      }
    }
  }

  .reservation_modal {
    .ant-modal {
      width: 100% !important;
      max-width: 450px;
      padding: 0 15px;
    }
    .ant-modal-content {
      box-shadow: none;
      .ant-modal-body {
        padding: 0;
        position: relative;
        .reservation_sidebar {
          box-shadow: 0 1px 10px rgba(0,0,0,0.16);
          header {
            padding-top: 50px;
            padding-bottom: 20px;
            border-color: ${themeGet("border.0", "#EBEBEB")};
            @media only screen and (max-width: 375px) {
              padding-top: 30px;
            }
          }
          .DateInput__small {
            width: 112px;
          }
          input,
          .DateRangePickerInput {
            padding: 0 9px;
          }
          footer {
            border-color: ${themeGet("border.0", "#EBEBEB")};
          }
        }
        > button.close {
          border: 0;
          padding: 0;
          top: 15px;
          right: 15px;
          height: auto;
          line-height: 1;
          position: absolute;
          font-size: 32px;
          background-color: transparent;
          color: ${themeGet("text.1", "#909090")};
          transition: all 0.3s ease;
          @media only screen and (max-width: 375px) {
            top: 10px;
            right: 10px;
            font-size: 25px;
          }
          &:hover,
          &:focus {
            outline: none;
            color: ${themeGet("text.0", "#2C2C2C")};
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .reservation_sidebar {
    padding: 0 !important;
    background-color: ${themeGet("color.1", "#ffffff")};
    header {
      padding-bottom: 27px;
      margin-bottom: 29px;
      padding: 25px 30px 26px 30px;
      border-bottom: 1px solid ${themeGet("border.0", "#EBEBEB")};
      @media only screen and (max-width: 375px) {
        padding: 25px 15px 26px 15px;
      }
      a {
        &:hover {
          color: #31b8bd;
        }
      }
    }

    p {
      color: ${themeGet("text.0", "#2C2C2C")};
      font-size: 15px;
      font-weight: 400;
      a {
        color: ${themeGet("primary.0", "#0077cc")};
        font-weight: 700;
        &:hover,
        &:focus {
          outline: 0;
          color: #0077cc;
          text-decoration: underline;
        }
      }
    }

    footer {
      padding: 25px 30px 28px 30px;
      margin-top: 29px;
      border-top: 1px solid ${themeGet("border.0", "#EBEBEB")};
      @media only screen and (max-width: 375px) {
        padding: 20px 15px 25px 15px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .grid_column .placeholder {
    max-width: 100%;
  }

  .ant-input-affix-wrapper {
    border-radius: 3px;
    border-color: ${themeGet("border.3", "#E6E6E6")};
  }

  .ant-input-search-icon::before {
    border-color: ${themeGet("border.3", "#E6E6E6")};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
  .ant-checkbox:hover .ant-checkbox-inner, 
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: ${themeGet("primary.0", "#0077cc")} !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(0, 132, 137, 0.2);
  }
  .modal-play .ant-modal-content {background-color: #000;}
  .modal-play .ant-modal-body {padding: 0;}
  .modal-play .ant-modal-footer {display: none;}
  .bg-path {position: relative;overflow: hidden;background-color: #f9f9f9;}
  .bg-path > img {position: absolute;}
  .bg-path > div {
    position: relative;
    background-color: #f9f9f9;
  }
  #root > .elluNn {
    height: auto;
  }
  div.ant-select-dropdown {
    margin-top: -5px !important;
  }
  .ant-select-dropdown .ant-select-item {
    color: #D2D2D2;
  }
  .cost-drop.ant-select-dropdown.dentist-proc-drop {
    min-width: 200px !important;
}
  .cost-drop.ant-select-dropdown {
      background-color: #F9F9F9;
      border: 1px solid #E3E3E3;
      padding: 15px;
      border-radius: 12px;
      min-width: 150px !important;
    }
    .cost-drop .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: inherit;      
      border: 1px solid #EFF7FF;
      border-radius: 7px;
    }
    .cost-drop.ant-select-dropdown .ant-select-item {
      color: #324755;
      &:hover {
        color: #0083ff;
      }
    }
    .cost-drop .rc-virtual-list-scrollbar-thumb {
        background: #C2C2C2 !important;
    }
    .react-images__blanket, .react-images__positioner {
      z-index: 99999 !important;    
    }
    .ant-modal-mask, 
    .ant-image-preview-mask {
        background-color: rgba(0,0,0,0.8) !important;
        z-index: 10004;
    }
    .ant-modal-wrap, 
    .ant-image-preview-wrap {
        z-index: 10001 !important;
    }
    .question-pop {
        width: 100% !important;
        max-width: 690px !important;
        padding: 15px !important;
    }
    .question-pop .ant-modal-header {border: 0 none;}
    .question-pop .ant-modal-title {
        color: #324755;
        font-size: 24px;
        font-weight: bold;
    }
    .question-pop div {
        color: #6E6E6E;
        font-size: 16px;
    }
    .question-pop .ant-modal-content {
      padding: 10px;
      border-radius: 22px;
    }
    .question-pop .ant-modal-body > div > div {
      margin-bottom: 15px;
    }
    .question-pop h5 {
      height: 44px;
      border-radius: 27px;
      border: 1px solid #E3E3E3;
      font-size: 12px;
      color: #989898;
      padding: 4px 15px;
      line-height: 34px;
      width: 100%;
      max-width: 500px;
      margin-bottom: 15px;
    }
    .question-pop .textarea {
      border-radius: 15px;
      border: 1px solid #E3E3E3;
      font-size: 12px;
      color: #989898;
      padding: 4px 15px;
      height: 90px;
      overflow-y: auto;
      width: 100%;
      max-width: 575px;
    }
    .question-pop button {
        font-size: 14px;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        height: 44px;
    }
    .question-pop .ant-modal-footer {display: none;}
    .question-pop .question-gallery {
        background-color: #F9F9F9;
        border-radius: 19px;
        padding: 12px;
        width: 100%;
        max-width: 575px;
        margin-bottom: 15px;
    }
    .question-pop .question-gallery > div {display: inline-block;padding: 5px;}
    .question-pop .question-gallery > div img {width: 100px;height: 100px;border-radius: 15px;object-fit: cover;}
    .question-pop button.ant-modal-close {
        color: #1E201D;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
        .ant-modal-close-x {
            width: 44px;
            height: 44px;
            font-size: 20px;
            line-height: 44px;
        }
    }
    @media (max-width: 575px) {
        .question-pop .question-gallery > div img {width: 96px;height: 96px;}
    }
    .ant-modal.question-pop {top: 20px;}
    .success-pop {
        .ant-modal-content {
            border-radius: 22px;
            .ant-modal-body {
                height: 400px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                > div {
                    margin: 0 auto;
                    text-align: center;
                    > span {
                        font-size: 70px;
                        color: #00D34F;
                        margin-bottom: 30px;
                    }
                    p {
                        margin: 0;
                        font-size: 20px;
                        color: #0D1115;
                        font-weight: bold;
                    }
                    span.post-notify {
                        font-size: 14px;
                        max-width: 400px;
                        color: #0D1115;
                        display: inline-block;
                    }
                }
            }
            .ant-modal-footer {
                display: none;
            }    
        }
    }
    
    .feedback-modal.ant-modal {
        max-width: 650px;
        width: 100% !important;
        @media (max-width: 767px){
            width: 100% !important;
            padding: 0 20px;
        }
    }
    .reply-likes .btn-link {padding-right: 2px;}
    .feedback-modal .ant-modal-content {
      border-top: 5px solid #0077cc;
      padding: 70px;
      @media (max-width: 575px){
        padding: 25px;
      }
      .feedback-modal .ant-modal-close {
        @media (max-width: 480px){
            top: -10px;
            right: -10px;
        }
        .ant-modal-close-x {
            @media (max-width: 575px){
                width: 36px;
                height: 36px;
                line-height: 36px;
            }
        }
      }  
    }
    .feedback-modal .ant-modal-header {
      padding: 0px 0px 16px;
    }
    .feedback-modal .ant-modal-title {
      color: #324755;
      font-weight: 600;
      font-size: 1.25rem;
    }
    .feedback-modal label.field-label {
      color: #324755;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
    }
    .feedback-modal .form-control input, .feedback-modal .form-control textarea {
      font-size: 14px;
      border-radius: 20px;
      padding: .375rem .75rem;
      border: 1px solid #ced4da;
      min-height: inherit;
    }
    .feedback-modal .form-control input {
      height: calc(2.25rem + 2px);
    }
    .feedback-modal .form-group {
        margin-bottom: 1rem;
    }
    .feedback-modal .ant-modal-body {
        padding: 16px 0;
    }
    .feedback-modal .ant-modal-footer {
        display: none;
    }
    .feedback-action {
        text-align: right;
        button {
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            height: 44px;
            border-radius: 22px;
            line-height: 24px;
        }
    }
    .d-none {display: none !important;}
    .no-data-contain {
        text-align: center;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
        display: table;
        padding: 60px 30px;
        margin: 50px auto;
        border-radius: 20px;
        width: 100%;
        max-width: 500px;
        background-color: #fff;
        h1 {
            font-size: 15px !important;
            line-height: 1.4 !important;
            color: #989898 !important;
        }
    }
    
    .review-pop {
        width: 680px !important;
        padding: 15px;
        .ant-modal-content {
            padding: 30px;
            border-radius: 22px;
            .ant-modal-header {
                border-bottom: 0 none;
                .ant-modal-title {
                    font-size: 24px;
                    color: #324755;
                    font-weight: bold;
                }    
            }
            .ant-modal-body {
                .form-group {
                    margin-bottom: 20px;
                    label {
                        font-size: 16px;
                        color: #6E6E6E;
                        font-weight: bold;
                        display: block;
                        margin-bottom: 5px;
                    }
                    .ant-radio-group {
                        label {
                            color: #0D1115;
                            font-size: 15px;
                            font-weight: normal;
                            display: inline-block;
                            span.ant-radio + * {
                                padding-left: 6px;
                            }
                        }
                    }
                    .ant-select {
                        max-width: 414px;
                        width: 100%;
                        .ant-select-selector {
                            border: 1px solid #E3E3E3;
                            border-radius: 27px;
                            height: 44px;
                            font-size: 12px;
                            color: #989898;
                            padding: 5px 11px;
                            input {
                                height: 40px;
                            }
                        }
                    }
                    textarea {
                        border: 1px solid #E3E3E3;
                        border-radius: 15px;
                    }
                    button {
                        font-size: 14px;
                        font-weight: bold;
                        text-transform: uppercase;
                        height: 44px;
                        border-radius: 22px;
                        line-height: 24px;
                        padding: 4px 25px;
                    }
                    .ant-rate-text {
                        font-size: 15px;
                        text-transform: capitalize;
                        margin-left: 20px;
                    }
                }
            }
            .ant-modal-footer {
                display: none;
            }
            .ant-modal-close {
                color: #1E201D;
                width: 44px;
                height: 44px;
                border-radius: 50%;
                box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
                .ant-modal-close-x {
                    width: 44px;
                    height: 44px;
                    font-size: 20px;
                    line-height: 44px;
                }
            }
        }
    }
    .re-modal {
        padding: 35px;
        overflow: inherit;
        .modal-pop {
            .ant-row {
                h5 {
                    font-size: 24px;
                    font-weight: bold;
                }
                h6 {
                    font-size: 20px;
                }
                .field-label {
                  font-size: 16px;  
                }
                .ant-select.ant-select-single:not(.ant-select-customize-input) {
                    max-width: 500px;
                    .ant-select-selector {
                        border-radius: 25px;
                        border-color: #E3E3E3;
                        min-height: 44px;
                        padding: 4px 15px;
                        input {
                          min-height: 42px;
                        }
                    }
                }
                textarea {
                    max-width: 570px;
                    border-radius: 20px;
                    padding: 15px;
                }
                .ant-btn-round {
                    height: 44px;
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;
                    width: 100%;
                    max-width: 170px;
                }
            }
        } 
        .modal-close {
            top: -7px;
            right: -3px;
            font-size: 25px;
            width: 44px;
            height: 44px;
            box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
            border-radius: 50%;
            text-align: center;
            line-height: 44px;
            z-index: 9;
            background-color: #fff;
        }
    }
    .ant-pagination {
        text-align: right;
        .ant-pagination-item {
            &:hover {
                background: #D7D7D7 !important;
                border-color: #D8E0E8 !important;
            }
            a {
                color: #0D1115 !important;
                &:hover {
                    color: #0D1115 !important;
                }
            }
        }
        .ant-pagination-item-active {
            background: #D7D7D7 !important;
            border-color: #D8E0E8 !important;
            a {
                color: #0D1115 !important;
            }
        }
    }  
    .procedure-cost-drop {
        width: 170px !important;
        min-width: 170px !important;
    }
  }
  .btn-link{
    border: none;
    background: none;
    box-shadow: none;
  }
  .text-right {
        text-align: right
    }
    .text-left {
        text-align: left;
    }
    .text-center {
        text-align: center;
    }
    .text-dark {
      color: #000
    }  
    .no-data {
        text-align: center;
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
        display: table;
        padding: 60px 30px;
        margin: 50px auto;
        border-radius: 20px;
        width: 100%;
        max-width: 500px;
        background-color: #fff;
        p {
            font-size: 15px !important;
            line-height: 1.4 !important;
            color: #989898 !important;
        }
    }
    .my-3{
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .f-13 {
        @media (max-width: 575px){
            font-size: 13px !important;
        }
      }
      .foo-logo {
        @media (max-width: 991px){
            margin-left: -7px;
        }
      }
      
      .q-details {
        .ant-row {
            .ant-col {
                .ant-card {
                    .ant-card-body {
                        .question-card {
                            .question-card-top {
                                display: none;
                            }
                            .question-card-mid {
                                margin-bottom: 15px;
                                p {
                                    b {
                                        font-size: 18px;
                                        color: #0D1115;
                                    }
                                }
                                small {
                                    color: #989898;
                                    font-size: 14px;
                                }
                                .profession {
                                    color: #989898;
                                    font-size: 12px;
                                }
                            }
                            .question-card-last {
                                h2 {
                                    font-size: 16px;
                                    font-weight: bold;
                                    color: #0077cc;
                                    &:hover {
                                        color: #0095ff;
                                    }
                                }
                                h5 {
                                    font-size: 18px;
                                    font-weight: bold;
                                    color: #0077cc;
                                    @media (max-width: 767px){
                                        line-height: normal;
                                    }
                                    &:hover {
                                        color: #0095ff;
                                    }
                                }
                                h6 {
                                    color: #314755;
                                    font-size: 16px;
                                }
                            }
                        }
                        .answer-card {
                            display: none;
                            > div {
                                img {
                                    width: 49px;
                                    height: 49px;
                                }
                            }
                        }        
                    }
                }                
            }
        }
      }
      
  html {
    scroll-behavior: smooth;
  }
  .cost-drop.dentist-drop.ant-select-dropdown {
    width: 150px !important;
}
#footer, .mid-footer, .copyright-footer {
    z-index: 99;
    position: relative;
    > div {
        max-width: inherit;
        padding: 0 120px;
        @media (max-width: 991px) {
            padding: 0 25px;
        }
    }
}
.flex-style {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.ant-btn[ant-click-animating-without-extra-node='true']::after {
    display: none;
    background: none !important;
}
.btn-link {
    background: none !important;
    span {
        background: none !important;
    }
    &:focus {
        background: none !important;
        span {
            background: none !important;
        }
    }
}
div.related-q {
    .ant-card {
        .ant-card-head {
            padding: 0 15px;
            .ant-card-head-title {
                font-size: 19px;
                padding: 12px 0;
                text-transform: capitalize;
            }
        }
        .ant-card-body {
            padding: 15px 15px 0;
        }
    }
}
div.affix-contain {
    .ant-affix {
        @media (max-width: 767px){
            padding: 0;
        }
    }
}
.ant-btn-primary {
    background-color: #0077cc !important;
    border-color: #0077cc !important;
    &:hover {
        background-color: #0095ff !important;
        border-color: #0095ff !important;
    }
    &[disabled] {
      color: rgba(255, 255, 255, 0.55);
    }
    &[disabled]:hover {
      color: rgba(255, 255, 255, 0.55);
    }
}
.profile-left {
    .ant-menu-item-active {
        .anticon {
            color: #0077cc !important;
        }
        a {
            color: #0077cc !important;
        }
    }
    .ant-menu-item-selected {
        .anticon {
            color: #0077cc !important;
        }
        a {
            color: #0077cc !important;
        }
    }
}
.head-right {
    > a:hover {
        color: #0077cc !important;
    }
}
.ant-breadcrumb-link {
    a {
        // color: #0077cc !important;
        &:hover {
            color: #0095ff !important;
        }
    }
}
.head-menu {
    > div {
        > div:first-child {
            max-width: 290px;
        }
    }
}
.pt-15 {
    padding-top: 15px;
}
.float-right {
    float:right;
}
.ls-head-right{
    cursor:pointer;
}
.cursor{
    cursor:pointer;
}
.mb3{
    margin-bottom:3px;
}
.mb5{
    margin-bottom:5px;
}
.mb10{
    margin-bottom:10px;
}
.mb15{
    margin-bottom:15px;
}
.mt30{
    margin-top:30px;
}
.mt8{
    margin-top:8px;
}
.w-100{
    width:100%;
}
.mt20{
    margin-top:20px;
}
.justify-center{
    justify:center;
}
.mb20{
    margin-bottom:20px;
}
.pb20{
    padding-bottom:20px;
}
.view-more-head{
     color:#1890ff;
     cursor:pointer;
}
h5.error-head {
    font-size: 27px;
    font-weight: 600;
    color: #757575;
    margin-bottom: 3px;
    text-align:center;
}
p.error-content {
    color: #757575;
    font-size: 17px;
    max-width: 300px;
    margin: 0px auto;
    text-align:center;
}
.booking-popup {
    h4 {
        font-size: 20px;
        margin-bottom: 15px;
        padding: 0 10px;
    }
    .ant-form-item {
        width: 50%;
        display: inline-block;
        padding: 0 10px;
        @media (max-width: 575px){
            width: 100%;
        }
    }
    .book-full {
        width: 100%;
    }
    .ant-modal-footer {
        display: none;
    }
    .ant-modal-title {
        padding: 0 10px;
        font-size: 20px;
    }
    button {
        font-size: 18px;
        // text-transform: uppercase;
        font-weight: bold;
        height: 44px;
        padding: 4px 20px;
    }
    .mr-3 {
        margin-right: 1rem;
    }
}
.call-popup {
    .ant-modal-body {
        text-align: center;
    }    
    .ant-modal-footer {
        display: none;
    }
    h5 {
        font-size: 27px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    p {
        font-size: 20px;
        margin-bottom: 0;
        color: #757575;
    }
    span {
        color: #0077cc;
        font-size: 20px;
    }
}
.blog-img{
    img{
        width:100%;
        height:100%;
        object-fit:cover;
        position:relative;
        
    }
}
 .ico-class{
    margin-right: 10px;
    min-width: 14px;
    text-align: center;
    display: inline-block;
   }
 header {
    .sticky-inner-wrapper {
        > nav.transparent {
            position: relative;
            border-bottom: 1px solid #e6e6e6;
        }
    }
 }
`;

export default GlobalStyle;
